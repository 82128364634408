import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FontFamily } from '../../models/fontFamily.model';
import { UIModule, UIButtonGroupOption } from '@bannerflow/ui';
import moment from 'moment';

@Component({
    imports: [UIModule],
    selector: 'font-style-header',
    templateUrl: './font-style-header.component.html',
    styleUrls: ['./font-style-header.component.scss']
})
export class FontStyleHeaderComponent {
    @Input() fontFamily: FontFamily;

    @Output('selectionChange')
    selectionChange: EventEmitter<string> = new EventEmitter<string>();

    parseDate(date: Date): string {
        return moment(date).format('YYYY-MM-DD');
    }

    selectedButton = 'compact';

    buttonOptions: UIButtonGroupOption[] = [
        {
            id: 'compact-list',
            icon: 'compact-list',
            value: 'compact'
        },
        {
            id: 'expanded-list',
            icon: 'expanded-list',
            value: 'expanded'
        },
        { id: 'dots', icon: 'dots', value: 'glyphs' }
    ];

    updateButtonToggle(event: string): void {
        this.selectedButton = event;
        this.selectionChange.emit(this.selectedButton);
    }
}
