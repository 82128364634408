import { Injectable } from '@angular/core';
import { FontStyle } from 'src/models';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SelectionService {
    private _fontStyles: FontStyle[] = [];
    fontStyleUpdate: Subject<void> = new Subject<void>();

    get fontStyles(): FontStyle[] {
        return this._fontStyles;
    }
    set fontStyles(fontStyles: FontStyle[]) {
        this._fontStyles = fontStyles;
        this.fontStyleUpdate.next();
    }

    isStyleSelected(fontStyle: FontStyle): boolean {
        return this.fontStyles.includes(fontStyle);
    }

    toggleSelection(fontStyle: FontStyle, select: boolean): void {
        if (select) {
            this.selectStyle(fontStyle);
        } else {
            this.deselectStyle(fontStyle);
        }
    }

    selectStyle(fontStyle: FontStyle): void {
        if (!this.isStyleSelected(fontStyle)) {
            this.fontStyles = [...this.fontStyles, fontStyle];
        }
    }

    deselectStyle(fontStyle: FontStyle): void {
        this.fontStyles = this.fontStyles.filter(someStyle => someStyle.id !== fontStyle.id);
    }
}
