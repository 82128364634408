@let fontStyleValue = fontStyle();
@let customTextValue = customText();
@let displayTypeValue = displayType();

<div
    class="item-font-container"
    [ngStyle]="{
        'font-family': fontFamilyName,
        'font-weight': fontStyleValue.weight
    }"
    [style.font-style]="fontStyleValue.italic ? 'italic' : ''">
    @if (customTextValue && hasLoaded) {
        <div
            class="item-font"
            #customCharacters></div>
    }
    @if (customTextValue && !hasLoaded) {
        <div class="item-font loading animated-background">
            {{ customTextValue }}
        </div>
    }
    @if (!customTextValue && displayTypeValue === 'compact') {
        <div class="item-font-container">
            <div class="item-font">
                <span
                    #characters
                    class="item-minimal"
                    [ngClass]="{
                        'loading animated-background': !hasLoaded
                    }"
                    >The quick brown fox jumps over the lazy dog</span
                >
                <span
                    #characters
                    class="item-minimal"
                    [ngClass]="{
                        'loading animated-background': !hasLoaded
                    }"
                    >0123456789</span
                >
                <span
                    #characters
                    class="item-minimal"
                    [ngClass]="{
                        'loading animated-background': !hasLoaded
                    }"
                    >!?€$£%</span
                >
            </div>
        </div>
    }
    @if (!customTextValue && displayTypeValue === 'expanded') {
        <div class="item-font-container-columns">
            <div class="item-font">
                <div
                    #characters
                    class="item-expanded"
                    [ngClass]="{
                        'loading animated-background': !hasLoaded
                    }">
                    A B C D E F G H I J K L M
                </div>
                <div
                    #characters
                    class="item-expanded"
                    [ngClass]="{
                        'loading animated-background': !hasLoaded
                    }">
                    N O P Q R S T U V W X Y Z
                </div>

                <div
                    #characters
                    class="item-margin item-expanded"
                    [ngClass]="{
                        'loading animated-background': !hasLoaded
                    }">
                    a b c d e f g h i j k l m
                </div>
                <div
                    #characters
                    class="item-expanded"
                    [ngClass]="{
                        'loading animated-background': !hasLoaded
                    }">
                    n o p q r s t u v w x y z
                </div>
            </div>

            <div class="item-font">
                <div
                    #characters
                    class="item-expanded"
                    [ngClass]="{
                        'loading animated-background': !hasLoaded
                    }">
                    ! ” ' # € % & / ( ) = ? ` ^ * _ : ;
                </div>
                <div
                    #characters
                    class="item-expanded"
                    [ngClass]="{
                        'loading animated-background': !hasLoaded
                    }">
                    © &#64; £ $ ∞ § | [ ] ≈ ± ´ ~ ™ – … ‚
                </div>

                <div
                    #characters
                    class="item-margin item-expanded"
                    [ngClass]="{
                        'loading animated-background': !hasLoaded
                    }">
                    š œ æ å ä ö õ ø ò ü ß Þ é í î ñ ÿ
                </div>
                <div
                    #characters
                    class="item-expanded"
                    [ngClass]="{
                        'loading animated-background': !hasLoaded
                    }">
                    1 2 3 4 5 6 7 8 9 0
                </div>
            </div>

            <div
                #characters
                class="item-font item-lorem"
                [ngClass]="{
                    'loading animated-background': !hasLoaded
                }">
                Lorem ipsum dolor sit amet, consectetur adipiscing elityu sed do eiusmod tempor
                incididunt ut labore et doloresitu magna aliqua. Ut enim ad minim veniam, quis
                nostrudipira exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit indonito ioluptate velit esse.
            </div>
        </div>
    }
    @if (!customTextValue && displayTypeValue === 'glyphs') {
        <div class="item-font-container">
            @if (fontStyleValue.unicodeGlyphs.length) {
                <div class="item-glyph-container">
                    @for (glyph of fontStyleValue.unicodeGlyphs; track glyph) {
                        <div class="glyph">
                            {{ convertUnicode(glyph) }}
                        </div>
                    }
                    <div class="glyph-metadata">
                        <div>
                            <strong>Number of glyphs:</strong>
                            {{ fontStyleValue.unicodeGlyphs.length }}
                            characters
                        </div>
                        <div>
                            <strong>Font weight:</strong>
                            {{ fontStyleValue.weight }}
                        </div>
                        <div>
                            <strong>Italic:</strong>
                            {{ fontStyleValue.italic }}
                        </div>
                        <div>
                            <strong>Uploaded file name:</strong>
                            {{ fontStyleValue.uploadedFileName }}
                        </div>
                    </div>
                </div>
            }
            @if (!fontStyleValue.unicodeGlyphs.length) {
                <div class="glyph-metadata">
                    This font do not support listing of glyphs.
                    <div class="glyph-metadata">
                        <div>
                            <strong>Font weight:</strong>
                            {{ fontStyleValue.weight }}
                        </div>
                        <div>
                            <strong>Italic:</strong>
                            {{ fontStyleValue.italic }}
                        </div>
                        <div>
                            <strong>Uploaded file name:</strong>
                            {{ fontStyleValue.uploadedFileName }}
                        </div>
                    </div>
                </div>
            }
        </div>
    }
</div>
