<div class="topbar-name">
    {{ selectedFontFamily?.name ?? 'All font families' }}
    @if (fontFamilies.length && !showAllFontFamilies() && isAllowedToModifyFont(selectedFontFamily)) {
        <ui-svg-icon
            class="settings-icon"
            icon="settings"
            (click)="onToggleSettings()"
            (keypress)="onToggleSettings()" />
    }
</div>

<div class="topbar-content">
    <div class="topbar-controls">
        @if (!isLoading() && (selectedFontFamily || (!selectedFontFamily && showAllFontFamilies()))) {
            <font-style-header
                [fontFamily]="selectedFontFamily"
                (selectionChange)="onChangeDisplayType($event)" />
        }
    </div>

    @if (!isLoading() && (selectedFontFamily || (!selectedFontFamily && showAllFontFamilies()))) {
        <div class="topbar-controls">
            <ui-input
                #customInput
                class="input"
                [value]="customText()"
                (valueChange)="onCustomTextChange($event)"
                [maxlength]="300"
                autocomplete="off"
                placeholder="Custom preview" />
        </div>
    }
</div>
@if (fontFamilies.length && !showAllFontFamilies()) {
    <div class="topbar-buttons">
        @if (fontStyles.length && isAllowedToModifyFont(selectedFontFamily)) {
            <ui-button
                icon="trash"
                [text]="'Delete (' + fontStyles.length + ')'"
                (click)="onConfirmStylesDeletion()"
                (keypress)="onConfirmStylesDeletion()" />
        }

        @if (!fontStyles.length) {
            <font-upload
                [(selectedFontFamily)]="selectedFontFamily"
                [hasDragDrop]="false" />
        }

        <ui-button
            type="default"
            text="Merge family"
            svgIcon="folder-move"
            class="upload-font-style"
            [uiDropdownTarget]="mergeMenu.uiDropdown" />

        <move-merge-menu
            #mergeMenu
            [fontFamily]="selectedFontFamily"
            mergeOrMove="merge"
            (menuChoiceClicked)="mergeFontFamily($event)">
        </move-merge-menu>
    </div>
}
