import { Directive, HostListener, Output, EventEmitter, HostBinding } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[dragDrop]'
})
export class DragDirective {
    @Output() filesDropped: EventEmitter<FileList> = new EventEmitter();

    @HostBinding('class') class = 'drag-drop-font hide';

    @HostListener('dragover', ['$event'])
    onDragOver(event: PointerEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.class = 'drag-drop-font';
    }

    @HostListener('dragleave', ['$event'])
    onDragLeave(event: PointerEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.class = 'drag-drop-font hide';
    }

    @HostListener('drop', ['$event'])
    onDrop(event: DragEvent): void {
        event.preventDefault();
        event.stopPropagation();

        this.class = 'drag-drop-font hide';

        if (event.dataTransfer && event.dataTransfer.files.length) {
            this.filesDropped.emit(event.dataTransfer.files);
        }
    }
}
