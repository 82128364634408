<div class="upload-from-device">
    <ui-file-dropzone
        accept=".woff,.ttf,.otf"
        hintText="Drag and drop a new font (.ttf, .otf, .woff) or upload it from your device"
        buttonText="Upload Fonts"
        maxFiles="20"
        (filesUploaded)="onFilesUploaded($event)" />

    <div class="note">Uploaded fonts will be added to the Fonts library.</div>
</div>
