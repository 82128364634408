<div style="text-align: center">
    <h1>Welcome to FontManager!</h1>
    <div style="max-width: 20rem; margin: auto; margin-bottom: 1rem">
        <ui-input
            [value]="brandId()"
            (valueChange)="updateBrandId($event)"
            label="Custom brand ID"
            placeholder="Input Brand ID" />
    </div>
    @let loggedIn = isLoggedIn();
    @if (loggedIn) {
        <ui-button
            text="Open Font Manager Dialog"
            (click)="openDialog()" />
    }
    <br /><br />
    @if (!loggedIn) {
        <ui-button
            text="Login"
            type="primary"
            (click)="login()" />
    }
    @if (loggedIn) {
        <ui-button
            text="Logout"
            type="primary"
            (click)="logout()" />
    }
</div>

<font-manager-dialog
    #fontManager
    [brandId]="brandId()" />
