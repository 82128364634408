@let fontStyleValue = fontStyle;
@let customTextValue = customText();
@let displayTypeValue = displayType();

<div class="wrapper">
    <div class="item-name-container">
        <div class="item-name">
            <ui-checkbox
                #checkbox
                class="item-name-checkbox"
                [selected]="isStyleSelected"
                (click)="selectionChanged()" />
        </div>
        <div class="item-name">
            @if (fontStyle.source === 'upload') {
                <ui-svg-icon
                    class="font-icon"
                    icon="font-file" />
            }

            @if (fontStyle.source === 'googleFonts') {
                <img
                    src="assets/icons/google-fonts.svg"
                    alt="Google Fonts" />
            }

            @if (!showRenameInput) {
                <span
                    class="item-name-text"
                    (click)="checkbox.setSelected(!isStyleSelected); selectionChanged()">
                    {{ fontStyleValue.name }}
                </span>
            }
            @if (showRenameInput) {
                <span class="item-name-text">
                    <ui-input
                        #fontStyleNameInput
                        [(value)]="newName"
                        autocomplete="off"
                        placeholder="Font Style Name" />
                </span>
            }
        </div>
    </div>

    <font-preview
        [fontFamily]="fontFamily()"
        [fontStyle]="fontStyle"
        [customText]="customTextValue"
        [displayType]="displayTypeValue"></font-preview>
</div>

@if (isAllowedToModifyFont(fontFamily())) {
    <div class="item-action-buttons">
        <button
            class="item-action-button"
            [uiDropdownTarget]="menu"
            (click)="toggleKebabMenu()">
            <ui-icon icon="more-standing" />
        </button>

        <ui-dropdown
            #menu
            [positions]="leftAlignedDropDownPositions">
            <ui-dropdown-item (click)="openRenameInput()">
                <div>
                    <ui-svg-icon
                        icon="edit"
                        class="menu-icon" />
                    Rename
                </div>
            </ui-dropdown-item>
            <ui-dropdown-item [uiDropdownTarget]="moveMenu.uiDropdown">
                <div>
                    <ui-svg-icon
                        icon="folder-move"
                        class="menu-icon" />
                    Move
                    {{ fontStyles.length > 1 ? fontStyles.length + ' styles' : 'style' }}
                    to family
                </div>
            </ui-dropdown-item>
            <ui-dropdown-item (click)="downloadStyle()">
                <div>
                    <ui-svg-icon
                        icon="download"
                        class="menu-icon" />
                    Download
                    {{ fontStyles.length > 1 ? fontStyles.length + ' styles' : 'style' }}
                </div>
            </ui-dropdown-item>
            <ui-dropdown-divider />
            <ui-dropdown-item (click)="confirmDeletion()">
                <div>
                    <ui-svg-icon
                        icon="delete"
                        class="menu-icon" />
                    Delete
                    {{ fontStyles.length > 1 ? fontStyles.length + ' styles' : 'style' }}
                </div>
            </ui-dropdown-item>
            <move-merge-menu
                #moveMenu
                [fontFamily]="fontFamily()"
                mergeOrMove="move"
                (menuChoiceClicked)="confirmMoveFontStyle($event)" />
        </ui-dropdown>
    </div>
} @else {
    <div class="item-action-buttons">
        <button
            class="item-action-button download"
            (click)="downloadStyle()">
            <ui-svg-icon icon="download" />
        </button>
    </div>
}
