import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { UIDropdownComponent, UIModule } from '@bannerflow/ui';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/services/auth.service';
import { FontService } from 'src/services/font-manager.service';
import { FontFamily } from '../../models/fontFamily.model';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'move-merge-menu',
    templateUrl: './merge-move-menu.component.html',
    styleUrls: ['./merge-move-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MergeMoveMenuComponent implements OnInit, OnDestroy {
    private authService = inject(AuthService);
    private changeDetectorRef = inject(ChangeDetectorRef);
    private fontService = inject(FontService);

    @ViewChild(UIDropdownComponent, { static: true })
    uiDropdown: UIDropdownComponent;

    @Output()
    menuChoiceClicked: EventEmitter<FontFamily> = new EventEmitter<FontFamily>();
    @Input() fontFamily: FontFamily;
    @Input() mergeOrMove: 'merge' | 'move';

    filteredFamilies: FontFamily[] = [];

    private fontsUpdatedSubscription: Subscription = Subscription.EMPTY;
    private afterFilesUploaded: Subscription = Subscription.EMPTY;

    ngOnInit(): void {
        this.initializeFonts();
        this.fontsUpdatedSubscription = this.fontService.fontsUpdate.subscribe(_ => {
            this.initAndRefreshView();
        });

        this.afterFilesUploaded = this.fontService.afterFilesUploaded.subscribe(_ => {
            this.initAndRefreshView();
        });
    }

    ngOnDestroy(): void {
        this.fontsUpdatedSubscription.unsubscribe();
        this.afterFilesUploaded.unsubscribe();
    }

    private initAndRefreshView(): void {
        this.fontFamily =
            this.fontService.fontFamilies.find(x => x.id === this.fontFamily.id) ?? this.fontFamily;
        this.initializeFonts();
        this.changeDetectorRef.detectChanges();
    }

    clicked = (family: FontFamily): void => {
        this.uiDropdown.popover.close();
        this.uiDropdown.parentDropdown?.popover.close();

        this.menuChoiceClicked.emit(family);
    };

    private initializeFonts(): void {
        const brandFamilies = this.fontService.getInstalledOnThisBrandFonts();
        const nonBrandFamilies = this.fontService.getNotOnThisBrandFonts();

        if (this.authService.isAdmin()) {
            this.filteredFamilies = [...brandFamilies, ...nonBrandFamilies].filter(
                family => family.id !== this.fontFamily.id
            );
        } else {
            this.filteredFamilies = brandFamilies.filter(family => family.id !== this.fontFamily.id);
        }
    }

    checkIsMovable(fromFamily: FontFamily): boolean {
        return fromFamily.isAccountFont === this.fontFamily.isAccountFont;
    }

    getMoveStyleTooltip(fontFamily: FontFamily): string {
        return fontFamily.isAccountFont
            ? `You can’t ${this.mergeOrMove} to a shared font`
            : `You can’t ${this.mergeOrMove} to a brand font`;
    }
}
