import { FontStyle } from '../models/fontFamily.model';

export function checkFontSource(fontStyles: FontStyle[]): 'upload' | 'googleFonts' | 'mixed' {
    if (fontStyles.length === 0) {
        throw new Error('Font styles array is empty');
    }

    const allUpload = fontStyles.every(style => style.source === 'upload');
    const allGoogleFonts = fontStyles.every(style => style.source === 'googleFonts');

    if (allUpload) {
        return 'upload';
    }
    if (allGoogleFonts) {
        return 'googleFonts';
    }

    return 'mixed';
}

export function getDefaultStyle(fontStyles: FontStyle[]): FontStyle {
    const isRegularStyle = (style: FontStyle): boolean =>
        style.name.toLowerCase() === 'regular' || (style.weight === 400 && !style.italic);

    const regularStyle = fontStyles.find(isRegularStyle);
    return regularStyle || fontStyles[Math.floor(fontStyles.length / 2)];
}
