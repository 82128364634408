import { Routes } from '@angular/router';
import { FontManagerComponent } from '../components/font-manager/font-manager.component';
import { canActivateGuard } from './auth.guard';
import { FontResolver } from './font.resolver';
import { InDialogComponent } from './in-dialog.component';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: (): string => {
            const inIframe = window.self !== window.top;
            return inIframe ? '/in-frame' : '/in-dialog';
        }
    },
    {
        path: 'in-dialog',
        component: InDialogComponent
    },
    {
        path: 'in-frame/:brand-id',
        component: FontManagerComponent,
        resolve: { data: FontResolver },
        canActivate: [canActivateGuard]
    }
];
