import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { enableProdMode, ErrorHandler } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { EnvironmentService } from 'src/services/environment.service';
import { GlobalErrorHandler } from 'src/services/global-error-handler.service';
import { GoogleTagManagerService } from 'src/services/google-tag-manager.service';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.router';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideHttpClient(withInterceptors([authHttpInterceptorFn])),
        provideRouter(routes),
        EnvironmentService,
        provideAuth0({
            domain: environment.origins.auth0Login,
            clientId: environment.auth0.clientId,
            authorizationParams: {
                redirect_uri: `${window.location.origin}/${window.location.search}`,
                audience: 'https://bannerflow.com/resources/',
                scope: 'openid fontmanager'
            },
            httpInterceptor: {
                allowedList: [`${environment.origins.fontManagerApi}/*`]
            },
            useRefreshTokens: true
        }),
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        GoogleTagManagerService,
        provideAnimations()
    ]
}).catch(err => console.error(err));
