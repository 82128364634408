import { inject, Injectable } from '@angular/core';
import * as opentype from 'opentype.js';
import {
    FontFamily,
    FontNamesExtension,
    NewFontFamilyDto,
    NewFontStyleDto
} from '../models/fontFamily.model';
import { FontService } from './font-manager.service';

@Injectable({
    providedIn: 'root'
})
export class FontUploadService {
    private fontService = inject(FontService);

    async uploadFiles(files: File[], selectedFontFamily: FontFamily | null = null): Promise<boolean> {
        const newFontFamilies: NewFontFamilyDto[] = [];
        const existingFontFamilies: NewFontFamilyDto[] = [];

        for (const file of files) {
            try {
                const { newFontStyle, fontFamilyName } = await this.validateFile(file);
                this.groupFontByFamily(
                    newFontStyle,
                    fontFamilyName,
                    newFontFamilies,
                    existingFontFamilies,
                    selectedFontFamily
                );
            } catch {
                return false;
            }
        }

        const updatedFontFamily = await this.createFontFamily(newFontFamilies, existingFontFamilies);
        this.resetUploads(newFontFamilies, existingFontFamilies);

        return !!updatedFontFamily;
    }

    validateFile(file: File): Promise<{ newFontStyle: NewFontStyleDto; fontFamilyName: string }> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);

            reader.onloadend = (): void => {
                try {
                    const bitArray = new Uint8Array(reader.result as ArrayBuffer);
                    const headerHex = this.extractFileHeader(bitArray);

                    if (headerHex === '774f4646' || headerHex === '0100' || headerHex === '4f54544f') {
                        const metaData = opentype.parse(reader.result as ArrayBuffer);
                        const fontNames = metaData.names as FontNamesExtension;

                        const newFontStyle = this.buildFontStyle(metaData, file, bitArray);
                        const fontFamilyName = this.setupFontNames(newFontStyle, fontNames);

                        this.extractGlyphs(metaData, newFontStyle);

                        resolve({ newFontStyle, fontFamilyName });
                    } else if (headerHex === '774f4632') {
                        reject('The Font Manager does not support woff2 files.');
                    } else {
                        reject('Invalid font file format.');
                    }
                } catch {
                    reject('Error parsing font file.');
                }
            };

            reader.onerror = (): void => {
                reject('Error reading file.');
            };
        });
    }

    groupFontByFamily(
        newFontStyle: NewFontStyleDto,
        fontFamilyName: string,
        newFontFamilies: NewFontFamilyDto[],
        existingFontFamilies: NewFontFamilyDto[],
        selectedFontFamily: FontFamily | null
    ): void {
        const hasExistingFontFamily = this.fontService.fontFamilies.find(
            fontFamily => fontFamily.name === fontFamilyName && !fontFamily.isAccountFont
        );

        if (hasExistingFontFamily || selectedFontFamily) {
            const existingFontFamily = existingFontFamilies.find(
                fontFamily => fontFamily.name === fontFamilyName
            );

            if (existingFontFamily) {
                existingFontFamily.fontStyles.push(newFontStyle);
            } else {
                const existingFontFamilyDto = new NewFontFamilyDto();
                if (selectedFontFamily) {
                    existingFontFamilyDto.id = selectedFontFamily.id;
                    existingFontFamilyDto.name = selectedFontFamily.name;
                } else {
                    existingFontFamilyDto.id = hasExistingFontFamily!.id;
                    existingFontFamilyDto.name = hasExistingFontFamily!.name;
                }
                existingFontFamilyDto.fontStyles.push(newFontStyle);
                existingFontFamilies.push(existingFontFamilyDto);
            }
        } else {
            const newFontFamily = newFontFamilies.find(
                fontFamily => fontFamily.name === fontFamilyName
            );

            if (newFontFamily) {
                newFontFamily.fontStyles.push(newFontStyle);
            } else {
                const newFontFamilyDto = new NewFontFamilyDto();
                newFontFamilyDto.name = fontFamilyName;
                newFontFamilyDto.brandId = this.fontService.brandId;
                newFontFamilyDto.fontStyles.push(newFontStyle);
                newFontFamilies.push(newFontFamilyDto);
            }
        }
    }

    async createFontFamily(
        newFontFamilies: NewFontFamilyDto[],
        existingFontFamilies: NewFontFamilyDto[]
    ): Promise<FontFamily | undefined> {
        let updatedFontFamily: FontFamily | undefined;

        for (const newFamily of newFontFamilies) {
            const createdFamily = await this.fontService.createFontFamily(newFamily);
            if (createdFamily && !updatedFontFamily) {
                updatedFontFamily = createdFamily;
            }
        }

        for (const existingFamily of existingFontFamilies) {
            await this.fontService.addFontStyles(existingFamily.id, existingFamily.fontStyles);
            updatedFontFamily = this.fontService.fontFamilies.find(
                family => family.id === existingFamily.id
            );
        }

        this.fontService.afterFilesUploaded.next(updatedFontFamily);
        return updatedFontFamily;
    }

    resetUploads(newFontFamilies: NewFontFamilyDto[], existingFontFamilies: NewFontFamilyDto[]): void {
        newFontFamilies.length = 0;
        existingFontFamilies.length = 0;
    }

    private buildFontStyle(
        metaData: opentype.Font,
        file: Blob & { name: string },
        bitArray: Uint8Array
    ): NewFontStyleDto {
        const newFontStyle = new NewFontStyleDto();

        newFontStyle.fontFile = btoa(
            Array.from(bitArray)
                .map(item => String.fromCharCode(item))
                .join('')
        );

        newFontStyle.weight = metaData.tables.os2?.usWeightClass
            ? Math.ceil(metaData.tables.os2.usWeightClass / 100) * 100
            : 400;

        newFontStyle.weight = Math.min(Math.max(newFontStyle.weight, 100), 900);
        newFontStyle.italic = !!metaData.tables.post?.italicAngle;
        newFontStyle.uploadedFileName = file.name;

        return newFontStyle;
    }

    private extractFileHeader(bitArray: Uint8Array): string {
        const headerHexArray = bitArray.subarray(0, 4);
        let headerHex = '';

        headerHexArray.forEach(item => {
            headerHex += item.toString(16);
        });

        return headerHex;
    }

    private extractGlyphs(metaData: opentype.Font, newFontStyle: NewFontStyleDto): void {
        // @ts-ignore
        const glyphs = metaData.glyphs.glyphs;
        for (const key in glyphs) {
            if (glyphs[key].unicode) {
                newFontStyle.unicodeGlyphs.push(glyphs[key].unicode);
            }
        }
    }

    private setupFontNames(newFontStyle: NewFontStyleDto, fontNames: FontNamesExtension): string {
        let fontFamilyName = '';

        const getFirstValueIn = (obj: { [key: string]: string }): string => obj[Object.keys(obj)[0]];

        if (fontNames.preferredFamily && getFirstValueIn(fontNames.preferredFamily).trim()) {
            fontFamilyName = getFirstValueIn(fontNames.preferredFamily).trim();
        } else if (fontNames.fontFamily && getFirstValueIn(fontNames.fontFamily).trim()) {
            fontFamilyName = getFirstValueIn(fontNames.fontFamily).trim();
        } else {
            fontFamilyName = newFontStyle.uploadedFileName.split('.')[0] || 'Undefined';
        }

        if (fontNames.preferredSubfamily && getFirstValueIn(fontNames.preferredSubfamily).trim()) {
            newFontStyle.name = getFirstValueIn(fontNames.preferredSubfamily).trim();
        } else if (fontNames.fontSubfamily && getFirstValueIn(fontNames.fontSubfamily).trim()) {
            newFontStyle.name = getFirstValueIn(fontNames.fontSubfamily).trim();
        } else {
            newFontStyle.name = newFontStyle.weight ? newFontStyle.weight.toString().trim() : 'Regular';
        }

        return fontFamilyName;
    }
}
