import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthGuard as Auth0Guard, AuthService } from '@auth0/auth0-angular';
import { filter, switchMap, take } from 'rxjs';

export const canActivateGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authService = inject(AuthService);
    const auth0Guard = inject(Auth0Guard);

    return authService.isAuthenticated$.pipe(
        filter(isAuthenticated => isAuthenticated !== null),
        take(1),
        switchMap(() => auth0Guard.canActivate(route, state))
    );
};
