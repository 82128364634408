<ui-dropdown>
    @for (mergeFamily of filteredFamilies; track mergeFamily) {
        <div>
            @if (checkIsMovable(mergeFamily)) {
                <div class="item">
                    <ui-dropdown-item
                        (click)="clicked(mergeFamily)"
                        [svgIcon]="mergeFamily.isAccountFont ? 'folder-shared' : 'folder'">
                        {{ mergeFamily.name }}
                    </ui-dropdown-item>
                </div>
            } @else {
                @if (!checkIsMovable(mergeFamily)) {
                    <div
                        [uiTooltip]="getMoveStyleTooltip(mergeFamily)"
                        class="item">
                        <ui-dropdown-item
                            disabled="true"
                            class="disabled"
                            [svgIcon]="mergeFamily.isAccountFont ? 'folder-shared' : 'folder'">
                            {{ mergeFamily.name }}
                        </ui-dropdown-item>
                    </div>
                }
            }
        </div>
    }
    @if (filteredFamilies.length === 0) {
        <ui-dropdown-item [disabled]="true">
            <i>No font families found</i>
        </ui-dropdown-item>
    }
</ui-dropdown>
