import { Injectable, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';

const ADMIN_ROLE = '2';
const EMPLOYEE_ROLE = '3';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private auth0Service = inject(Auth0Service);

    private user = toSignal(this.auth0Service.user$);

    isAdmin = computed(() => {
        const user = this.user();
        return !!user && (user.role === ADMIN_ROLE || user.employee === EMPLOYEE_ROLE);
    });

    isAuthenticated = toSignal(this.auth0Service.isAuthenticated$, { initialValue: false });

    login(): void {
        this.auth0Service.loginWithRedirect();
    }

    logout(): void {
        this.auth0Service.logout();
    }
}
