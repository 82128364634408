import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontStyle, FontFamily } from '../../models/fontFamily.model';
import { FontStyleComponent } from './font-style.component';
import { LazyLoaderComponent } from './lazy-loader.component';

@Component({
    imports: [CommonModule, LazyLoaderComponent, FontStyleComponent],
    selector: 'font-styles',
    templateUrl: './font-styles.component.html',
    styleUrls: ['./font-styles.component.scss']
})
export class FontStylesComponent {
    @Input() fontFamily: FontFamily;
    @Input() customText: string;
    @Input() displayType: string;

    @Output()
    deleteFontStyle: EventEmitter<FontStyle> = new EventEmitter<FontStyle>();
}
