import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, input, OnInit, output, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
    UIAlertDialogService,
    UIConfirmDialogConfig,
    UIConfirmDialogResult,
    UIConfirmDialogService,
    UIModule,
    UINotificationService
} from '@bannerflow/ui';
import { Brand, FontFamily, IFontFamilyJSON } from 'src/models';
import { SortBy } from '../../pipes/sortBy.pipe';
import { FontService } from '../../services/font-manager.service';

@Component({
    imports: [CommonModule, UIModule, FormsModule, SortBy],
    selector: 'font-settings',
    templateUrl: './font-settings.component.html',
    styleUrls: ['./font-settings.component.scss']
})
export class FontSettingsComponent implements OnInit {
    private fontService = inject(FontService);
    private self = inject(ElementRef, { self: true });
    private uiAlertDialogService = inject(UIAlertDialogService);
    private uiConfirmDialogService = inject(UIConfirmDialogService);
    private uiNotificationService = inject(UINotificationService);

    selectedFontFamily = input.required<FontFamily>();

    brandList = viewChild<ElementRef>('brandList');

    toggleSettings = output<boolean>();

    selectedBrands: Brand[] = [];
    newFontFamilyName = '';
    settingsChanged = false;

    get brands(): Brand[] {
        return this.fontService.brands;
    }

    ngOnInit(): void {
        this.setupName();
        this.selectedBrands = this.selectBrandsFrom(this.selectedFontFamily());
        this.self.nativeElement.scrollIntoView({
            behavior: 'smooth'
        });
    }

    private selectBrandsFrom(fontFamily: FontFamily): Brand[] {
        const brands: Brand[] = this.fontService.brands.filter((brand: Brand) =>
            fontFamily.brandIds.includes(brand.id)
        );

        return brands;
    }

    private setupName(): void {
        const selectedFontFamilyName = this.selectedFontFamily().name ?? '';
        this.newFontFamilyName = selectedFontFamilyName;
    }

    async confirmFamilyDeletion(fontFamily?: FontFamily): Promise<void> {
        const fontFamilyName = fontFamily ? fontFamily.name : this.selectedFontFamily().name;
        const answer: UIConfirmDialogResult = await this.uiConfirmDialogService.confirm({
            headerText: 'Are you sure?',
            text: `Do you really want to delete the font family/font style <strong>${fontFamilyName}</strong>?
            <br/><br/>Please note that this will have no effect on existing creatives using this font, but the font will no longer be available in Creative Studio.`,
            confirmText: 'Delete Font Family'
        });

        if (answer === 'confirm') {
            await this.deleteFontFamily(fontFamily ? fontFamily : this.selectedFontFamily(), true);
        }
    }

    async deleteFontFamily(fontFamilyToDelete: FontFamily, showSuccess: boolean): Promise<void> {
        const shouldEmitDeletion = true;
        await this.fontService.deleteFontFamily(fontFamilyToDelete, shouldEmitDeletion);

        if (showSuccess) {
            this.uiNotificationService.open('Font family deleted successfully!', {
                type: 'success',
                placement: 'top',
                autoCloseDelay: 3000
            });
        }
    }

    brandSelectionChange(): void {
        this.settingsChanged = true;
    }

    async saveSettings(): Promise<void> {
        // Save new name
        if (this.selectedFontFamily().name !== this.newFontFamilyName) {
            const nameTaken: FontFamily | undefined = this.fontService.fontFamilies.find(
                (fontFamily: FontFamily) =>
                    fontFamily.name.toLowerCase() === this.newFontFamilyName.toLowerCase()
            );

            if (!this.newFontFamilyName || nameTaken) {
                this.uiAlertDialogService.show({
                    headerText: 'An error has occured',
                    text: this.newFontFamilyName
                        ? 'The font family name must be unique.'
                        : 'Name cannot be empty.',
                    confirmText: 'Ok'
                });
            } else {
                await this.fontService.renameFontFamily(
                    this.selectedFontFamily(),
                    this.newFontFamilyName
                );

                this.selectedFontFamily().name = this.newFontFamilyName;

                this.settingsChanged = false;
            }
        }
        if (this.settingsChanged) {
            const selectedBrandIds: string[] = this.selectedBrands.map((brand: Brand) => brand.id);

            const isFontAccountUpdate = this.selectedFontFamily().isAccountFont;
            const isConversionToAccountFont =
                !this.selectedFontFamily().isAccountFont && selectedBrandIds.length > 1;

            let answer = '';
            if (isFontAccountUpdate) {
                answer = await this.uiConfirmDialogService.confirm(
                    this.getConfirmationDialogText('updateAccountFont')
                );
            } else if (isConversionToAccountFont) {
                answer = await this.uiConfirmDialogService.confirm(
                    this.getConfirmationDialogText('convertToAccountFont')
                );
            }

            if (answer === 'confirm') {
                const response: IFontFamilyJSON | null = await this.fontService.updateAccountFont(
                    this.selectedFontFamily(),
                    selectedBrandIds
                );
                const updatedFamily = FontFamily.deserialize(response);
                if (response && updatedFamily) {
                    const updatedFontFamilies: FontFamily[] = this.fontService.fontFamilies.filter(
                        fontFamily => fontFamily.id !== this.selectedFontFamily().id
                    );
                    updatedFontFamilies.push(updatedFamily);
                    this.fontService.fontFamilies = updatedFontFamilies;
                }
            }

            this.settingsChanged = false;
        }
        this.toggleSettings.emit(true);
    }

    private getConfirmationDialogText(
        operation:
            | 'updateAccountFont'
            | 'convertToAccountFont'
            | 'convertToBrandFont'
            | 'updateBrandFont'
    ): UIConfirmDialogConfig {
        switch (operation) {
            case 'updateAccountFont':
            case 'convertToAccountFont':
                return {
                    headerText: 'Are you sure?',
                    text: `This action makes the font family
                        <strong>${this.selectedFontFamily().name ?? ''}</strong> shared between brands.
                        Only admin users will have access to edit a shared font`,
                    confirmText: 'Share Font Family'
                };
            case 'convertToBrandFont':
            case 'updateBrandFont':
            default:
                return {
                    headerText: '',
                    text: '',
                    confirmText: ''
                };
        }
    }

    checkIsBrandControlDisabled(brand: Brand): boolean {
        const isBrandFont = !this.selectedFontFamily().isAccountFont;
        const hasOnlyOneBrand = this.selectedFontFamily().brandIds.length === 1;
        const isCurrentBrand = brand.id === this.fontService.brandId;

        return hasOnlyOneBrand && isCurrentBrand && isBrandFont && this.selectedBrands.includes(brand);
    }

    cancelSettings(): void {
        this.toggleSettings.emit(true);
    }
}
