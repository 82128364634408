import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    OnDestroy,
    OnInit,
    viewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { UIModule } from '@bannerflow/ui';
import { tap, debounceTime, switchMap } from 'rxjs';
import { SelectedFontsComponent } from 'src/components/font-manager/upload-from-external-library/selected-fonts/selected-fonts.component';
import {
    Font,
    FontStyles,
    FontStyle,
    PaginationMeta,
    FetchFontsResponse
} from 'src/domain/font-import.type';

import { FontImportService } from 'src/services/font-import.service';

@Component({
    imports: [CommonModule, UIModule, SelectedFontsComponent, ReactiveFormsModule],
    selector: 'upload-from-external-library',
    templateUrl: './upload-from-external-library.component.html',
    styleUrls: ['./upload-from-external-library.component.scss'],
    providers: [FontImportService]
})
export class UploadFromExternalLibraryComponent implements OnInit, AfterViewInit, OnDestroy {
    private destroyRef = inject(DestroyRef);
    private fontImportService = inject(FontImportService);
    private formBuilder = inject(FormBuilder);

    fontsList = viewChild<ElementRef>('fontsList');

    importing$ = this.fontImportService.importing$;
    searchForm = this.createForm();
    fontDefaultStyles: FontStyles = {};
    isLoading = false;
    isLoadingMore = false;

    private paginationMeta: PaginationMeta = {
        currentPage: 1,
        totalPages: 0,
        itemsPerPage: 10,
        totalItems: 0
    };
    private fontObserver: IntersectionObserver;
    private scrollObserver: IntersectionObserver;
    private observedFonts = new Set<HTMLElement>();
    private observedScrollElement: HTMLElement | null = null;

    constructor() {
        this.initIntersectionObservers();
    }

    ngOnInit(): void {
        this.setupSearchSubscription();
        this.triggerInitialSearch();
    }

    ngAfterViewInit(): void {
        this.observeFontElements();
    }

    ngOnDestroy(): void {
        this.disconnectObservers();
    }

    toggleAccordion(font: Font, expanded: boolean): void {
        if (expanded) {
            this.loadFontStyles(font);
        }
    }

    onFamilySelection(event: MouseEvent, font: Font): void {
        event.stopPropagation();
        this.fontImportService.toggleFamilySelection(font);
    }

    isFontLoaded(fontUrl: string): boolean {
        return this.fontImportService.loadedFonts.has(fontUrl);
    }

    getFonts(): Font[] {
        return this.fontImportService.fonts;
    }

    isFamilySelected(font: Font): boolean {
        return this.fontImportService.isFamilySelected(font);
    }

    isFamilyIndeterminate(font: Font): boolean {
        return this.fontImportService.isFamilyIndeterminate(font);
    }

    toggleStyleSelection(font: Font, style: FontStyle): void {
        this.fontImportService.toggleStyleSelection(font, style);
    }

    isStyleSelected(font: Font, style: FontStyle): boolean {
        return this.fontImportService.isStyleSelected(font, style);
    }

    private createForm(): FormGroup {
        return this.formBuilder.group({
            searchTerm: ['']
        });
    }

    private initIntersectionObservers(): void {
        this.fontObserver = new IntersectionObserver(this.onFontIntersection.bind(this), {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        });

        this.scrollObserver = new IntersectionObserver(this.onScrollIntersection.bind(this), {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        });
    }

    private onFontIntersection(entries: IntersectionObserverEntry[]): void {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this.loadFontElement(entry.target as HTMLElement);
            }
        });
    }

    private onScrollIntersection(entries: IntersectionObserverEntry[]): void {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                this.loadMoreFonts();
            }
        });
    }

    private loadFontElement(fontElement: HTMLElement): void {
        const family = fontElement.getAttribute('data-family');
        if (family && this.fontDefaultStyles[family]) {
            const { file, name, italic } = this.fontDefaultStyles[family];
            if (!this.isFontLoaded(file)) {
                this.fontImportService.loadFont(file, `${family}-${name}-${italic}`);
            }
        }
    }

    private setupSearchSubscription(): void {
        this.searchForm
            .get('searchTerm')
            ?.valueChanges.pipe(
                takeUntilDestroyed(this.destroyRef),
                tap(() => (this.isLoading = true)),
                debounceTime(300),
                switchMap(searchTerm =>
                    this.fontImportService.fetchFonts(searchTerm, this.paginationMeta.itemsPerPage, 1)
                )
            )
            .subscribe(({ items, meta }: FetchFontsResponse) => {
                this.paginationMeta = meta;
                this.fontImportService.fonts = items;
                this.updateFontDefaultStyles(items);
                this.isLoading = false;
                this.observeFontElements();
                this.observeLastFontElements();
            });
    }

    private updateFontDefaultStyles(fonts: Font[]): void {
        this.fontDefaultStyles = fonts.reduce((acc, font) => {
            acc[font.family] = this.fontImportService.getDefaultStyle(font);
            return acc;
        }, {} as FontStyles);
    }

    private triggerInitialSearch(): void {
        this.searchForm.get('searchTerm')?.setValue('', { emitEvent: true });
    }

    private observeFontElements(): void {
        setTimeout(() => {
            const fontElements =
                this.fontsList()?.nativeElement.querySelectorAll('.font-sample-header');
            fontElements.forEach((fontElement: HTMLElement) => {
                if (!this.observedFonts.has(fontElement)) {
                    this.fontObserver.observe(fontElement);
                    this.observedFonts.add(fontElement);
                }
            });
        }, 0);
    }

    private observeLastFontElements(): void {
        setTimeout(() => {
            const fontElements =
                this.fontsList()?.nativeElement.querySelectorAll('.font-item-container');

            if (fontElements.length > 0) {
                const fontElementAnchorPos = 3;
                const targetIndex =
                    fontElements.length >= fontElementAnchorPos
                        ? fontElements.length - fontElementAnchorPos
                        : fontElements.length - 1;
                const targetElement = fontElements[targetIndex];

                if (this.shouldObserveScroll(targetElement)) {
                    if (this.observedScrollElement) {
                        this.scrollObserver.unobserve(this.observedScrollElement);
                    }

                    this.scrollObserver.observe(targetElement);
                    this.observedScrollElement = targetElement;
                }
            }
        }, 0);
    }

    private shouldObserveScroll(element: HTMLElement): boolean {
        return (
            element !== this.observedScrollElement &&
            this.paginationMeta.currentPage < this.paginationMeta.totalPages
        );
    }

    private loadMoreFonts(): void {
        if (this.isLoadingMore || this.paginationMeta.currentPage >= this.paginationMeta.totalPages) {
            return;
        }

        this.isLoadingMore = true;
        this.fontImportService
            .fetchFonts(
                this.searchForm.get('searchTerm')?.value,
                this.paginationMeta.itemsPerPage,
                this.paginationMeta.currentPage + 1
            )
            .subscribe(({ items, meta }: FetchFontsResponse) => {
                this.fontImportService.fonts.push(...items);
                this.paginationMeta = meta;
                this.updateFontDefaultStyles(this.fontImportService.fonts);
                this.observeFontElements();
                this.observeLastFontElements();
                this.isLoadingMore = false;
            });
    }

    private loadFontStyles(font: Font): void {
        font.styles.forEach(style => {
            this.fontImportService.loadFont(style.file, `${font.family}-${style.name}-${style.italic}`);
        });
    }

    private disconnectObservers(): void {
        this.fontObserver?.disconnect();
        this.scrollObserver?.disconnect();
    }
}
