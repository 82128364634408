import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { FetchFontsResponse, Font, FontStyle, ImportFontResponse } from 'src/domain/font-import.type';
import { IFontFamilyJSON } from 'src/models';
import { EnvironmentService } from './environment.service';

@Injectable()
export class FontImportService {
    fonts: Font[] = [];
    selectedFonts: Font[] = [];
    loadedFonts: Set<string> = new Set();
    importing$: Observable<boolean>; // state for active import

    private environmentService = inject(EnvironmentService);
    private http = inject(HttpClient);
    private apiUrl: string;
    private importingSubject = new BehaviorSubject<boolean>(false);

    constructor() {
        this.apiUrl = this.environmentService.getOrigin('fontManagerApi');
        this.importing$ = this.importingSubject.asObservable();
    }

    fetchFonts(searchTerm: string, itemsPerPage: number, page: number): Observable<FetchFontsResponse> {
        return this.http
            .get<FetchFontsResponse>(`${this.apiUrl}/integrations/googleFonts/find`, {
                headers: { 'Content-Type': 'application/json' },
                params: {
                    searchTerm,
                    page,
                    itemsPerPage
                }
            })
            .pipe(
                map(response => ({
                    items: this.processFonts(response.items),
                    meta: response.meta
                }))
            );
    }

    private processFonts(fonts: Font[]): Font[] {
        return fonts.map(font => ({
            ...font,
            styles: this.sortStyles(font.styles)
        }));
    }

    getDefaultStyle(font: Font): FontStyle {
        const isRegularStyle = (style: FontStyle): boolean =>
            style.name.toLowerCase() === 'regular' || (style.weight === 400 && !style.italic);

        const regularStyle = font.styles.find(isRegularStyle);
        return regularStyle || font.styles[Math.floor(font.styles.length / 2)];
    }

    loadFont(fontUrl: string, fontFamily: string): void {
        if (this.loadedFonts.has(fontUrl)) return;

        const font = new FontFace(fontFamily, `url(${fontUrl})`);
        font.load()
            .then(loadedFont => {
                document.fonts.add(loadedFont);
                this.loadedFonts.add(fontUrl);
            })
            .catch(error => console.error(`Failed to load font: ${fontFamily} from ${fontUrl}`, error));
    }

    toggleFamilySelection(font: Font): void {
        if (this.importingSubject.value) return;

        const index = this.selectedFonts.findIndex(f => f.family === font.family);
        if (index > -1) {
            this.selectedFonts.splice(index, 1);
        } else {
            this.selectedFonts.push({
                family: font.family,
                styles: [...font.styles]
            });
        }
    }

    toggleStyleSelection(font: Font, style: FontStyle): void {
        if (this.importingSubject.value) return;

        const selectedFont = this.findSelectedFont(font.family);
        if (selectedFont) {
            const styleIndex = selectedFont.styles.findIndex(s => this.isSameStyle(s, style));
            if (styleIndex > -1) {
                selectedFont.styles.splice(styleIndex, 1);
                if (selectedFont.styles.length === 0) {
                    this.selectedFonts = this.selectedFonts.filter(f => f.family !== font.family);
                }
            } else {
                selectedFont.styles.push(style);
                selectedFont.styles = this.sortStyles(selectedFont.styles);
            }
        } else {
            this.selectedFonts.push({
                family: font.family,
                styles: [style]
            });
        }
    }

    isFamilySelected(font: Font): boolean {
        const selectedFont = this.findSelectedFont(font.family);
        return !!selectedFont && selectedFont.styles.length === font.styles.length;
    }

    isFamilyIndeterminate(font: Font): boolean {
        const selectedFont = this.findSelectedFont(font.family);
        return (
            !!selectedFont &&
            selectedFont.styles.length > 0 &&
            selectedFont.styles.length < font.styles.length
        );
    }

    isStyleSelected(font: Font, style: FontStyle): boolean {
        const selectedFont = this.findSelectedFont(font.family);
        if (!selectedFont) return false;
        return selectedFont.styles.some(s => this.isSameStyle(s, style));
    }

    private findSelectedFont(family: string): Font | undefined {
        return this.selectedFonts.find(f => f.family === family);
    }

    private isSameStyle(style1: FontStyle, style2: FontStyle): boolean {
        return (
            style1.name === style2.name &&
            style1.weight === style2.weight &&
            style1.italic === style2.italic
        );
    }

    private sortStyles(styles: FontStyle[]): FontStyle[] {
        return styles.sort((a, b) => {
            const weightComparison = a.weight - b.weight;
            if (weightComparison !== 0) return weightComparison;
            return (a.italic ? 1 : 0) - (b.italic ? 1 : 0);
        });
    }

    async importSelectedFonts(
        brandId: string
    ): Promise<{ success: boolean; data?: IFontFamilyJSON[] }> {
        this.importingSubject.next(true);
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        const items = this.selectedFonts.map(({ family, styles }) => ({
            family,
            styles: styles.map(style => ({
                name: style.name,
                weight: style.weight,
                italic: style.italic,
                file: style.file
            }))
        }));

        const body = { brandId, items };

        try {
            const response = await firstValueFrom(
                this.http.post<ImportFontResponse>(
                    `${this.apiUrl}/integrations/googleFonts/import`,
                    body,
                    { headers }
                )
            );
            return { success: true, data: response.fontFamilies };
        } catch (error) {
            console.error('Failed to import fonts', error);
            return { success: false };
        } finally {
            this.importingSubject.next(false);
        }
    }
}
