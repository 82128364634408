<div class="settings-container">
    <div class="settings-arrow">▲</div>

    <div class="settings-content">
        <div class="settings-name">Font family name</div>
        <div
            class="settings-name-container"
            ui-theme="minimal">
            <ui-input
                #nameInput
                [(ngModel)]="newFontFamilyName"
                autocomplete="off"
                [ngModelOptions]="{ standalone: true }"
                placeholder="Font Family Name" />

            <ui-button
                class="settings-delete"
                icon="trash"
                (click)="confirmFamilyDeletion()"
                text="Delete Family" />
        </div>
        @if (brands.length) {
            <div>Use on</div>
            @if (selectedBrands) {
                <div>
                    <div>
                        <ui-checkbox
                            (click)="
                                brands.length === selectedBrands.length
                                    ? brandList.deselectAll()
                                    : brandList.selectAll()
                            "
                            [selected]="brands.length === selectedBrands.length"
                            class="settings-brand-all"
                            (selectedChange)="brandSelectionChange()"
                            label="All brands" />
                    </div>
                    <ui-selectable-list
                        #brandList
                        class="settings-brand-container"
                        [(selected)]="selectedBrands"
                        (selectedChange)="brandSelectionChange()"
                        [multiSelect]="true">
                        @for (brand of brands | sortBy: 'name'; track brand) {
                            <div class="settings-brand-item">
                                <ui-checkbox
                                    class="brand-checkbox"
                                    [class.selected-box]="selectedBrands.includes(brand)"
                                    [value]="brand"
                                    [disabled]="checkIsBrandControlDisabled(brand)" />
                                <ui-image
                                    class="settings-brand-icon"
                                    [image]="brand.logoUri" />
                                <span class="settings-brand-name">{{ brand.name }}</span>
                            </div>
                        }
                    </ui-selectable-list>
                </div>
            }
        }
        @if (!brands) {
            <div>fontService is missin' .brands</div>
        }

        @if (!selectedBrands) {
            <div>settings missin' .selectedBrands</div>
        }

        <div
            class="settings-action"
            ui-theme="minimal">
            <ui-button
                text="Cancel"
                (click)="cancelSettings()" />
            <ui-button
                text="Save"
                type="primary"
                (click)="saveSettings()" />
        </div>
    </div>
</div>
