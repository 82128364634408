@let fontFamilyValue = fontFamily();
<div
    class="font-family-item"
    [class.active]="isSelectedFontFamily()"
    (click)="select()"
    [uiTooltip]="
        fontFamilyValue.isAccountFont && !isAdmin()
            ? 'This font family is shared between multiple brands. Only admin users can edit this family'
            : fontFamily().name
    ">
    <div class="font-family-data">
        @switch (fontSource) {
            @case ('upload') {
                <ui-svg-icon
                    class="font-icon upload-icon"
                    [icon]="fontFamilyValue.isAccountFont ? 'folder-shared' : 'font-file'" />
            }
            @case ('googleFonts') {
                <img
                    class="font-icon google-fonts-icon"
                    src="assets/icons/google-fonts.svg"
                    alt="Google Fonts" />
            }
            @case ('mixed') {
                <ui-svg-icon
                    class="font-icon mixed-icon"
                    [icon]="fontFamilyValue.isAccountFont ? 'folder-shared' : 'folder'" />
            }
        }
        <div class="item">
            <div class="name">{{ fontFamilyValue.name }}</div>
            <div class="number">({{ fontFamilyValue.fontStyles.length }})</div>
        </div>

        <div class="menu-container">
            <ui-svg-icon
                class="font-icon"
                icon="arrow-right" />
        </div>
    </div>
</div>
