import { Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'lazy-loader',
    template: `
        <ng-container>
            <ng-content></ng-content>
        </ng-container>
    `,
    styles: [':host {display: block;}']
})
export class LazyLoaderComponent {}
