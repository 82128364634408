<ui-dialog
    #dialog="ui-dialog"
    [config]="{
        width: '99%',
        height: '90%',
        maxWidth: '1400px',
        padding: 0,
        escKeyClose: false,
        headerText: 'Manage Fonts'
    }">
    <ng-template ui-dialog-template>
        <font-manager />
    </ng-template>
</ui-dialog>
