import { Injectable, inject } from '@angular/core';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { EnvironmentService } from './environment.service';

@Injectable({ providedIn: 'root' })
export class TrackerService {
    private enviromentService = inject(EnvironmentService);

    static newrelic: BrowserAgent | undefined;

    initNewRelic(): void {
        const newRelicConf = this.enviromentService.getNewRelicConfig();
        if (!newRelicConf.enabled) {
            return;
        }

        const options = {
            init: {
                session_replay: {
                    enabled: true,
                    collect_fonts: true,
                    inline_images: true,
                    inline_stylesheet: true,
                    block_selector: '',
                    sampling_rate: 1,
                    error_sampling_rate: 100,
                    mask_all_inputs: false,
                    mask_input_options: {
                        color: false,
                        date: false,
                        datetime_local: false,
                        email: true,
                        month: false,
                        number: false,
                        range: false,
                        search: false,
                        tel: true,
                        text: false,
                        time: false,
                        url: false,
                        week: false,
                        text_area: false,
                        select: false
                    }
                },
                distributed_tracing: { enabled: true },
                privacy: { cookies_enabled: true },
                ajax: {
                    deny_list: [
                        'bam.eu01.nr-data.net',
                        'aptrinsic.com',
                        'fullstory.com',
                        'esp-eu.aptrinsic.com'
                    ]
                }
            },
            info: {
                beacon: 'bam.eu01.nr-data.net',
                errorBeacon: 'bam.eu01.nr-data.net',
                licenseKey: newRelicConf.licenseKey,
                applicationID: newRelicConf.applicationId,
                sa: 1
            },
            loader_config: {
                accountID: newRelicConf.accountId,
                trustKey: newRelicConf.trustKey,
                agentID: newRelicConf.agentId,
                licenseKey: newRelicConf.licenseKey,
                applicationID: newRelicConf.applicationId
            }
        };

        try {
            new BrowserAgent(options);
            TrackerService.newrelic = (window as any).newrelic;
        } catch (e: any) {
            console.error('Failed to initialize NewRelic', e);
        }
    }

    static captureException(error: Error | string): void {
        TrackerService.newrelic?.noticeError(error);
    }
}
