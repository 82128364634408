export type FontBufferData = Readonly<{
    buffer: ArrayBuffer;
    type: string;
}>;

export type FontRenameRequest = Readonly<{
    name: string;
}>;
export type FontStyleResponse = Readonly<FontStyle & { fontFamilyId: string }>;
export class FontStyle {
    id: string;
    name: string;
    uploadedFileName: string;
    createdAt: Date;
    source: 'upload' | 'googleFonts';
    deletedAt?: Date;
    fontUrl: string;
    weight: number;
    italic: boolean;
    unicodeGlyphs: number[];

    static deserialize(json: FontStyleResponse): FontStyle | null {
        if (!json) {
            return null;
        }

        const fontStyle: FontStyle = new FontStyle();
        fontStyle.id = json.id;
        fontStyle.createdAt = json.createdAt;
        fontStyle.deletedAt = json.deletedAt;
        fontStyle.fontUrl = json.fontUrl;
        fontStyle.italic = json.italic;
        fontStyle.source = json.source;
        fontStyle.name = json.name;
        fontStyle.uploadedFileName = json.uploadedFileName;
        fontStyle.weight = json.weight;
        fontStyle.unicodeGlyphs = json.unicodeGlyphs
            ? json.unicodeGlyphs.map((glyph: number) => glyph)
            : [];

        return fontStyle;
    }
}

export class FontFamily {
    id: string;
    name: string;
    brandIds: string[] = [];
    createdAt: Date;
    deletedAt?: Date | null | undefined;
    fontStyles: FontStyle[] = [];
    isAccountFont: boolean;

    static deserialize(json: IFontFamilyJSON | null): FontFamily | null {
        if (!json) {
            return null;
        }

        const fontFamily: FontFamily = new FontFamily();
        fontFamily.id = json.id;
        fontFamily.name = json.name;
        fontFamily.createdAt = json.createdAt;
        fontFamily.deletedAt = json.deletedAt;
        fontFamily.fontStyles = json.fontStyles.reduce<FontStyle[]>((acc, fontStyle) => {
            const deserializedFontStyle: FontStyle | null = FontStyle.deserialize(fontStyle);
            if (deserializedFontStyle) {
                return [...acc, deserializedFontStyle];
            }

            return acc;
        }, []);
        fontFamily.brandIds = json.brandIds || [];
        fontFamily.isAccountFont = json.isAccountFont;

        return fontFamily;
    }
}
export interface IFontFamilyJSON {
    id: string;
    name: string;
    isAccountFont: boolean;
    brandIds: string[];
    createdAt: Date;
    deletedAt: Date | null | undefined;
    fontStyles: FontStyleResponse[];
}

export class NewFontFamilyDto {
    id: string;
    name: string;
    brandId: string;
    fontStyles: NewFontStyleDto[] = [];
}

export class NewFontStyleDto {
    name: string;
    uploadedFileName: string;
    fontFile: string;
    weight: number;
    italic: boolean;
    unicodeGlyphs: number[] = [];
}

export type FontNamesExtension = opentype.FontNames & {
    preferredFamily?: { [key: string]: string };
    preferredSubfamily?: { [key: string]: string };
};

export const generateUID: () => string = () => {
    const numberA = (Math.random() * 46656) | 0;
    const numberB = (Math.random() * 46656) | 0;
    const firstPart = `000${numberA.toString(36)}`.slice(-3);
    const secondPart = `000${numberB.toString(36)}`.slice(-3);

    return firstPart + secondPart;
};

// tslint:disable-next-line: typedef
export const generateTestFams = async (nFamilies: number): Promise<FontFamily[]> =>
    new Promise(res => {
        const testDate = new Date();
        const fontFamilies: FontFamily[] = [];

        for (let step = 0; step < nFamilies; step++) {
            const nameAndId = generateUID();

            fontFamilies.push({
                id: nameAndId,
                name: nameAndId,
                isAccountFont: false,
                brandIds: ['someHash'],
                createdAt: testDate,
                deletedAt: null,
                fontStyles: []
            });
        }
        res(fontFamilies);
    });
