import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { UIModule, UINotificationService } from '@bannerflow/ui';
import { FontImportService } from 'src/services/font-import.service';
import { FontService } from 'src/services';
import { FontFamily, IFontFamilyJSON } from 'src/models';
import { Font, FontStyle } from 'src/domain/font-import.type';

@Component({
    imports: [CommonModule, UIModule],
    selector: 'selected-fonts',
    templateUrl: './selected-fonts.component.html',
    styleUrls: ['./selected-fonts.component.scss']
})
export class SelectedFontsComponent {
    private fontImportService = inject(FontImportService);
    private fontService = inject(FontService);
    private uiNotificationService = inject(UINotificationService);

    importing$ = this.fontImportService.importing$;

    getSelectedFontsCount(): number {
        return this.fontImportService.selectedFonts.reduce(
            (count, font) => count + font.styles.length,
            0
        );
    }

    getSelectedFamiliesCount(): number {
        return this.fontImportService.selectedFonts.length;
    }

    getSelectedFonts(): Font[] {
        return this.fontImportService.selectedFonts;
    }

    toggleFamilySelection(font: Font): void {
        this.fontImportService.toggleFamilySelection(font);
    }

    toggleStyleSelection(font: Font, style: FontStyle): void {
        this.fontImportService.toggleStyleSelection(font, style);
    }

    importFonts = async (): Promise<void> => {
        const { success, data } = await this.fontImportService.importSelectedFonts(
            this.fontService.brandId
        );
        if (!(success && data)) {
            this.uiNotificationService.open('Error importing fonts. Please try again.', {
                type: 'error',
                placement: 'top',
                autoCloseDelay: 3000
            });
            return;
        }

        const newFontFamilies = data
            .map((fontFamilyData: IFontFamilyJSON) => FontFamily.deserialize(fontFamilyData))
            .filter((fontFamily): fontFamily is FontFamily => fontFamily !== null);

        this.fontService.fontFamilies = [...this.fontService.fontFamilies, ...newFontFamilies];

        this.uiNotificationService.open('Fonts imported successfully!', {
            type: 'success',
            placement: 'top',
            autoCloseDelay: 3000
        });
    };

    isImporting(): Observable<boolean> {
        return this.importing$;
    }
}
