import { Component, inject, input, viewChild } from '@angular/core';
import { UIDialogDirective, UIModule } from '@bannerflow/ui';
import { FontService } from 'src/services';
import { FontManagerComponent } from '../font-manager/font-manager.component';

@Component({
    imports: [UIModule, FontManagerComponent],
    selector: 'font-manager-dialog',
    templateUrl: './font-manager-dialog.component.html',
    styleUrls: ['./font-manager-dialog.component.scss']
})
export class FontManagerDialogComponent {
    private fontService = inject(FontService);

    brandId = input.required<string>();

    dialog = viewChild.required<UIDialogDirective>('dialog');

    async openFontManager(): Promise<void> {
        this.fontService.brandId = this.brandId();

        this.fontService.brands = await this.fontService.getAllBrands();

        this.fontService.fontFamilies = await this.fontService.getAllFontFamilies();

        this.dialog().open();
    }
}
