import { DOCUMENT } from '@angular/common';
import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FontFamily, FontStyle } from 'src/models';
import { FontService } from 'src/services';

/**
 * @type https://developer.mozilla.org/en-US/docs/Web/API/FontFaceSet
 */
export type FontFaceSet = Readonly<{
    check: (font: string) => boolean;
    status: 'loaded' | 'loading';
}>;

export type FontFace = Readonly<{
    load: () => Promise<void>;
}>;
@Injectable({
    providedIn: 'root'
})
export class FontLoaderService {
    private document = inject(DOCUMENT);
    private fontService = inject(FontService);
    private rendererFactory = inject(RendererFactory2);

    private renderer: Renderer2;
    private _fonts$ = new BehaviorSubject<string[]>([]);
    fonts$ = this._fonts$.asObservable();

    get fonts(): string[] {
        return this._fonts$.getValue();
    }

    set fonts(val: string[]) {
        this._fonts$.next(val);
    }

    constructor() {
        this.renderer = this.rendererFactory.createRenderer(this.document, null);
    }

    addLoadedFont(title: string): void {
        this.fonts = [...this.fonts, title];
    }

    async loadStyle(fontFamily: FontFamily, fontStyle: FontStyle): Promise<void> {
        const name = this.fontService.getFontStyleName(fontFamily, fontStyle);

        const loadedFont = new FontFace(name, `url('${fontStyle.fontUrl}')`);

        try {
            await loadedFont.load();
            const newStyle = this.renderer.createElement('style');
            newStyle.className = 'font-link';
            newStyle.id = name;
            newStyle.appendChild(
                this.renderer.createText(`
                @font-face {
                    font-family: '${name}';
                    src: url('${fontStyle.fontUrl}');
                    font-weight: ${fontStyle.weight};
                    ${fontStyle.italic ? 'font-style: italic;' : ''}
                }
            `)
            );
            this.addToDom(name, newStyle);
        } catch {
            console.error('unable to load font');
        }
    }

    private addToDom(name: string, newStyle: HTMLStyleElement): void {
        if (this.document.head && !this.fonts.includes(name)) {
            this.renderer.appendChild(this.document.head, newStyle);
            this.addLoadedFont(name);
        }
    }

    removeStyle(name: string): void {
        const allLinksElement = this.document.querySelector(`#${name}`);
        if (allLinksElement) {
            allLinksElement.remove();
            this.fonts = this.fonts.filter(loadedFont => loadedFont !== name);
        }
    }
}
