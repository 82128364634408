@for (fontStyle of fontFamily?.fontStyles; track fontStyle) {
    <div>
        <lazy-loader>
            <div class="styles-container">
                <font-style
                    [fontFamily]="fontFamily"
                    [fontStyle]="fontStyle"
                    [customText]="customText"
                    [displayType]="displayType"
                    (deleteFontStyle)="deleteFontStyle.emit($event)">
                </font-style>
            </div>
        </lazy-loader>
    </div>
}
