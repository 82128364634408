import { CommonModule } from '@angular/common';
import { Component, inject, signal, viewChild } from '@angular/core';
import { UIModule } from '@bannerflow/ui';
import { AuthService } from 'src/services/auth.service';
import { EnvironmentService } from 'src/services/environment.service';
import { FontManagerDialogComponent } from '../components/font-manager-dialog/font-manager-dialog.component';

@Component({
    selector: 'in-dialog',
    templateUrl: './in-dialog.component.html',
    imports: [CommonModule, UIModule, FontManagerDialogComponent]
})
export class InDialogComponent {
    private authService = inject(AuthService);
    private environmentService = inject(EnvironmentService);

    fontManager = viewChild<FontManagerDialogComponent>('fontManager');

    brandId = signal(
        this.environmentService.isProduction() ? '5508363c3b6ee30810ca29d6' : '5588134bcbbb8401a4ff2487'
    );

    isLoggedIn = this.authService.isAuthenticated;

    constructor() {
        localStorage.removeItem('brandId');
    }

    updateBrandId(newValue: string): void {
        this.brandId.set(newValue);
    }

    openDialog(): void {
        this.fontManager()?.openFontManager();
    }

    login(): void {
        this.authService.login();
    }

    logout(): void {
        this.authService.logout();
    }
}
