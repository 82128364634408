import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HttpProxyService {
    private http = inject(HttpClient);

    get = async <T>(url: string, options: {}): Promise<T> =>
        lastValueFrom(this.http.get<T>(url, options));

    post = async <T, K>(url: string, data: K, options: {}): Promise<T> =>
        lastValueFrom(this.http.post<T>(url, data, options));

    patch = async <T, K>(url: string, data: K, options: {}): Promise<T> =>
        lastValueFrom(this.http.patch<T>(url, data, options));

    put = async <T, K>(url: string, data: K, options: {}): Promise<T> =>
        lastValueFrom(this.http.put<T>(url, data, options));

    delete = async <T>(url: string, options: {}): Promise<T> =>
        lastValueFrom(this.http.delete<T>(url, options));
}
