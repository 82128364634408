import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from './environment.service';

declare global {
    interface Window {
        dataLayer: {
            'gtm.start': number;
            event: 'gtm.js';
        }[];
    }
}

@Injectable({ providedIn: 'root' })
export class GoogleTagManagerService {
    private environmentService = inject(EnvironmentService);

    initializeGtm(): void {
        const gtmId = this.environmentService.getGoogleTagManagerId();

        if (!gtmId) {
            return;
        }
        this.initTagManager(gtmId);
    }

    private initTagManager(gtmId: string): void {
        window.dataLayer ??= [];
        window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        });

        const firstScriptTag = document.getElementsByTagName('script')[0];
        const gtmScriptTag = document.createElement('script');
        gtmScriptTag.async = true;
        gtmScriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
        firstScriptTag.parentNode?.insertBefore(gtmScriptTag, firstScriptTag);
    }
}
