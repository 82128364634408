@if (!brandId) {
    <div style="color: red; padding: 4rem">ERROR: Brand ID not defined.</div>
} @else {
    <ui-tabs>
        <ui-tab name="Library">
            <div class="flex-container ui-scrollbar">
                <div class="sidebar ui-scrollbar">
                    <div
                        class="all-font-families"
                        (click)="viewAllFontFamilies()">
                        View all font families
                        <ui-svg-icon
                            class="font-icon"
                            icon="arrow-right" />
                    </div>
                    <div class="font-family-header">
                        <div class="font-family-header-text">
                            <ui-icon icon="text" />
                            Installed on this brand
                        </div>
                    </div>
                    @for (fontFamily of getInstalledOnThisBrandFonts(); track fontFamily.id) {
                        @if (!isLoading && !fontFamily) {
                            <div class="font-family-item">
                                <div class="font-family-item-text">
                                    <i>No fonts found</i>
                                </div>
                            </div>
                        }

                        @if (fontFamily) {
                            <div class="font-family-item">
                                <font-family
                                    [fontFamily]="fontFamily"
                                    [isSelectedFontFamily]="selectedFontFamily?.id === fontFamily.id"
                                    (selectFontFamily)="selectFontFamily($event, false)"
                                    (openSettings)="openSettings($event)"
                                    (confirmMergeFontFamily)="confirmMergeFontFamily($event)"
                                    (addFontStyles)="setup()" />
                            </div>
                        }
                    }

                    @if (!isLoading && isAdmin()) {
                        <div class="font-family-header font-family-extra-border">
                            <div class="font-family-header-text">
                                <ui-icon icon="text" /> Not on this brand
                            </div>
                        </div>
                    }

                    @for (fontFamily of getNotOnThisBrandFonts(); track fontFamily.id) {
                        @if (fontFamily) {
                            <div class="font-family-item">
                                <font-family
                                    [fontFamily]="fontFamily"
                                    [isSelectedFontFamily]="selectedFontFamily?.id === fontFamily.id"
                                    (selectFontFamily)="selectFontFamily($event, false)"
                                    (openSettings)="openSettings($event)"
                                    (confirmMergeFontFamily)="confirmMergeFontFamily($event)"
                                    (addFontStyles)="setup()" />
                            </div>
                        }
                    }
                </div>
                <div
                    class="right-column"
                    ui-theme="minimal">
                    <div class="topbar-wrapper">
                        <font-manager-topbar
                            [selectedFontFamily]="selectedFontFamily"
                            [showAllFontFamilies]="showAllFontFamilies"
                            [isLoading]="isLoading"
                            [customText]="customText"
                            (changeDisplayType)="changeDisplayType($event)"
                            (confirmStylesDeletion)="confirmStylesDeletion()"
                            (toggleSettings)="toggleSettings()"
                            (customTextChange)="customTextChange($event)"
                            (mergeToFontFamily)="mergeFontFamily($event)" />
                    </div>
                    <div class="main ui-scrollbar">
                        @if (showSettings) {
                            <div @openClose>
                                <font-settings
                                    #settingsComponent
                                    [selectedFontFamily]="selectedFontFamily"
                                    (toggleSettings)="toggleSettings($event)" />
                            </div>
                        }

                        @if (showAllFontFamilies) {
                            <font-families
                                [customText]="customText"
                                [displayType]="displayType"
                                (confirmMergeFontFamily)="
                                    mergeFontFamily($event.mergeTo, $event.fontFamily)
                                "
                                (openSettings)="openSettings($event)">
                            </font-families>
                        }

                        @if (selectedFontFamily && !showAllFontFamilies) {
                            <font-styles
                                #fontStyles
                                [(customText)]="customText"
                                (deleteFontStyle)="deleteFontStyle($event)"
                                [fontFamily]="selectedFontFamily"
                                [displayType]="displayType" />
                        }
                        @if (
                            !isLoading &&
                            !selectedFontFamily?.fontStyles?.length &&
                            !showAllFontFamilies
                        ) {
                            <div class="main-nothing-found">
                                <i>No font styles found</i>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ui-tab>
        <ui-tab name="Upload from device">
            <upload-from-device />
        </ui-tab>
        <ui-tab name="Import from external library">
            <upload-from-external-library />
        </ui-tab>
    </ui-tabs>
}
