@let fontFamilyValue = fontFamily();

@let stylesLength = fontFamilyValue.fontStyles.length;
@let customTextValue = customText();
@let displayTypeValue = displayType();

@let fontFamilyDefaultStyle = getFontFamilyStyles(fontFamilyValue);
@let fontFamilyItalic = fontFamilyDefaultStyle.italic ? 'italic' : '';
@let fontFamilyWeight = fontFamilyDefaultStyle.weight;
@let fontFamilyName = getFontFamilyName(fontFamilyValue);

<div class="wrapper">
    <div class="item-name-container">
        <div class="item-name">
            @switch (fontFamilyValue.source) {
                @case ('upload') {
                    <ui-svg-icon
                        class="font-icon"
                        icon="font-file" />
                }
                @case ('googleFonts') {
                    <img
                        src="assets/icons/google-fonts.svg"
                        alt="Google Fonts" />
                }
                @case ('mixed') {
                    <ui-svg-icon
                        class="font-icon"
                        icon="folder" />
                }
            }

            <span class="item-name-text">
                {{ fontFamilyValue.name }}
            </span>
            <span class="item-name-text-small">
                ({{ stylesLength }} {{ stylesLength === 1 ? 'style' : 'styles' }})
            </span>
        </div>
    </div>
    <font-preview
        [fontFamily]="fontFamilyValue"
        [fontStyle]="fontFamilyDefaultStyle"
        [customText]="customTextValue"
        [displayType]="displayTypeValue"></font-preview>
</div>

<div class="menu-container">
    @if (isAllowedToModifyFont(fontFamilyValue)) {
        <button
            class="menu"
            [uiDropdownTarget]="fontActions"
            preventClick>
            <ui-icon icon="more-standing" />
        </button>
    }
    <ui-dropdown
        class="font-actions"
        #fontActions>
        <ui-dropdown-item
            svgIcon="folder-move"
            class="move"
            [uiDropdownTarget]="mergeMenu.uiDropdown">
            Merge to family
        </ui-dropdown-item>

        <ui-dropdown-item
            svgIcon="settings"
            class="settings"
            (click)="settings()">
            Settings
        </ui-dropdown-item>

        <move-merge-menu
            #mergeMenu
            [fontFamily]="fontFamilyValue"
            mergeOrMove="merge"
            (menuChoiceClicked)="mergeFontFamily($event)">
        </move-merge-menu>
    </ui-dropdown>
</div>
