import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FontService } from 'src/services';

@Injectable({ providedIn: 'root' })
export class FontResolver {
    private fontService = inject(FontService);

    async resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Promise<void> {
        const brandId = route.paramMap.get('brand-id') || '';
        localStorage.setItem('brandId', brandId);

        this.fontService.brandId = brandId;

        this.fontService.brands = await this.fontService.getAllBrands();

        this.fontService.fontFamilies = await this.fontService.getAllFontFamilies();
    }
}
