@if (hasDragDrop) {
    <div
        class="drag-drop-font hide"
        id="drag-drop-font"
        dragDrop
        (filesDropped)="uploadFiles($event)">
        <div class="drag-drop-font-overlay">
            <div class="drag-drop-font-hover">
                <ui-svg-icon
                    set="misc"
                    icon="other-sizes-arrow-upload" />
                Drop to upload
            </div>
        </div>
        <div class="drag-drop-font-underlay"></div>
    </div>
}

<input
    #fileUpload
    id="file-upload{{ selectedFontFamily?.id }}"
    class="fileinput"
    type="file"
    multiple="true"
    (change)="uploadFiles($event.target.files)"
    placeholder="Upload font style"
    accept=".woff,.ttf,.otf" />
<label for="file-upload{{ selectedFontFamily?.id }}">
    <!-- Todo: FIX inline styling fuckup -->
    @if (!selectedFontFamily) {
        <ui-button
            id="upload-new-font"
            style="margin: auto; width: 100%"
            type="primary"
            text="Upload"
            icon="plus-skinny-minimal"
            [uiTooltip]="'Upload new font file (.ttf, .otf, .woff)'"
            uiTooltipPosition="bottom" />
    }
    @if (selectedFontFamily && isAllowedToModifyFont(selectedFontFamily)) {
        <ui-button
            id="upload-new-font-file-to-family"
            type="default"
            text="Upload style"
            icon="plus-skinny-minimal"
            class="upload-font-style"
            [uiTooltip]="'Upload new font file to this font family (.ttf, .otf, .woff)'"
            uiTooltipPosition="left" />
    }
    <!-- @if (selectedFontFamily) {
        <div
            class="submenu-item">
            <ui-icon
                icon="plus-skinny-minimal"
                class="submenu-item-icon"></ui-icon> Upload
        </div>
    } -->
</label>
