import { Injectable, ErrorHandler } from '@angular/core';
import { TrackerService } from './tracker.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
    handleError(error: Error | string): void {
        TrackerService.captureException(error);
    }
}
