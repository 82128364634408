import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    standalone: true,
    name: 'sortBy'
})
export class SortBy implements PipeTransform {
    transform<T>(object: T[], key: keyof T): T[] {
        return object.sort((a, b) => {
            const [string1, string2] = this.isStrings(a[key], b[key]);
            if (string1 && string2) {
                return string1.toUpperCase().localeCompare(string2.toUpperCase());
            }
            if (a[key] < b[key]) {
                return -1;
            } else if (a[key] > b[key]) {
                return 1;
            }
            return 0;
        });
    }

    isStrings(a: unknown, b: unknown): String[] {
        if (this.isString(a) && this.isString(b)) {
            return [a, b];
        }

        return [];
    }

    private isString(x: unknown): x is String {
        return typeof x === 'string';
    }
}
