<div class="selected-content">
    <div class="selected-header">Selected Fonts</div>
    <div class="selected-list">
        @if (getSelectedFonts().length === 0) {
            <div class="no-fonts-selected">No fonts selected.</div>
        }
        @for (font of getSelectedFonts(); track font.family) {
            <div class="selected-item">
                <div class="selected-font-family">
                    {{ font.family }}
                    <div class="actions">
                        <div
                            class="delete-icon"
                            (click)="toggleFamilySelection(font)"
                            (keypress)="toggleFamilySelection(font)"
                            [class.disabled]="isImporting() | async">
                            <ui-svg-icon icon="close" />
                        </div>
                    </div>
                </div>
                @for (style of font.styles; track style.name) {
                    <div class="selected-font-style-item">
                        <div class="font-style-name">{{ style.name }}</div>
                        <div class="actions">
                            <div
                                class="delete-icon"
                                (click)="toggleStyleSelection(font, style)"
                                (keypress)="toggleStyleSelection(font, style)"
                                [class.disabled]="isImporting() | async">
                                <ui-svg-icon icon="close" />
                            </div>
                        </div>
                    </div>
                }
            </div>
        }
    </div>
</div>
<div class="import-button-container">
    <ui-button
        id="import-google-font"
        class="import-button"
        type="primary"
        text="Import"
        icon="download"
        [disabled]="getSelectedFonts().length === 0"
        [uiTooltip]="'Import into your Brand Library'"
        [submit]="importFonts"
        aria-label="Import selected fonts into your Brand Library" />
    <div
        class="selected-summary"
        [class.hidden]="getSelectedFontsCount() === 0">
        You have {{ getSelectedFontsCount() }} fonts selected from {{ getSelectedFamiliesCount() }} font
        families.
    </div>
</div>
