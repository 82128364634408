@for (fontFamily of fontFamilies; track fontFamily.id) {
    <lazy-loader>
        <div class="styles-container">
            <font-family-item
                [fontFamily]="fontFamily"
                [customText]="customText()"
                [displayType]="displayType()"
                (confirmMergeFontFamily)="mergeFontFamily($event)"
                (openSettings)="settings($event)">
            </font-family-item>
        </div>
    </lazy-loader>
}
